import {
  CascadeClassifier,
  Mat,
  RectVector,
  VideoCapture,
} from '@techstark/opencv-js/src/types/opencv'
import { loadHaar } from '../../utils'
import { captureImageFromVideo } from './parser'
import { devLogError, devLog } from '../../log'

type StartAutoFaceCheck = {
  videoEl: HTMLVideoElement
  onCameraStop?: () => void | Promise<void>
  onImageCaptured?: (image: string | null) => void | Promise<void>
}

export function startAutoFaceCheck({
  videoEl,
  onCameraStop,
  onImageCaptured,
}: StartAutoFaceCheck) {

  try {
    const cv = window.cv
    devLog('is cv ready?', cv)
    if (!cv || !cv.Mat || !cv.RectVector || !cv.CascadeClassifier ) {
      setTimeout(() => startAutoFaceCheck({ videoEl, onCameraStop, onImageCaptured }), 500)
      return
    }

    let src: Mat
    let gray: Mat
    let cap: VideoCapture
    let faces: RectVector
    let classifier: CascadeClassifier

    const viewHeight = videoEl.height
    const viewWidth = videoEl.width

    let started = false
    let streaming = true

    const clearData = () => {
      src?.delete()
      gray?.delete()
      faces?.delete()
      classifier?.delete()
    }

    const restart = async () => {
      src = new cv.Mat(viewHeight, viewWidth, cv.CV_8UC4)
      gray = new cv.Mat()
      cap = new cv.VideoCapture(videoEl)
      faces = new cv.RectVector()
      classifier = new cv.CascadeClassifier()
      await loadHaar(classifier)
    }

    const run = async () => {
      const FPS = 30;
      try {
        if (!streaming) {
          return clearData()
        }

        if (!started) {
          await restart()
          started = true
        }

        let begin = Date.now();

        // start processing.
        cap.read(src)
        cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0)

        // detect faces.
        classifier.detectMultiScale(gray, faces, 1.1, 3, 0)
        const faceCount = faces.size()

        if (faceCount >= 1) {
          const image = await captureImageFromVideo(videoEl)
          streaming = false
          started = false
          onCameraStop?.()
          onImageCaptured?.(image)
        }

        let delay = 1000/FPS - (Date.now() - begin);
        setTimeout(run, delay)
      } catch (err) {
        devLogError(err)
        throw err
      }
    }

    // schedule the first one.
    setTimeout(run, 0)
  } catch (error) {
    devLogError(error)
    setTimeout(() => startAutoFaceCheck({ videoEl }), 500)
  }
}
